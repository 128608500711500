import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";

const Test = () => {
  const [iframeVisible, setIframeVisible] = useState(false);

  const handleOpen = () => {
    setIframeVisible(true);

    window.addEventListener("message", (event) => {
      if (event?.data?.token) {
        setIframeVisible(false);
        console.log("token:", event?.data?.token);
      }
    });
  };

  return (
    <div>
      <Button onClick={handleOpen}>Open</Button>
      <Modal
        title=""
        open={iframeVisible}
        footer={null}
        centered
        closable={false}
      >
        <iframe
          // src="http://localhost:3001/login?apiKey=d28ccf68-f8b6-431f-9045-a9cddae51449&redirect=1&registered_from=tkc"
          src={`https://core-ui.dev.beingtheparent.com/login?apiKey=${process.env.REACT_APP_API_KEY}&registered_from=brandlabs`}
          id="myIframe"
          title="login-sso"
          width="100%"
          height="530"
        ></iframe>
      </Modal>
    </div>
  );
};

export default Test;
