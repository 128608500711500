import React from "react";
import logo from "../assets/images/btp-spinner.svg"
const Spinner = () => (
  <div className="d-flex justify-content-center spinner-screen">
    <div className="spinner" role="status">
      <span className="">
        {" "}
        <img
          className="spinner-image"
          src={logo}
          alt="image"
        />
      </span>
    </div>
  </div>
);
export default Spinner;
