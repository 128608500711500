import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import errorHandling from "../components/errorhandling/ErrorHandle";

const SendEmail = {
  contactUs: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.sendEmailUrl,
        "post",
        {},
        { ...data, email: "arjun@beingtheparent.com" }
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },
};

export default SendEmail;
