import React, { useContext, useState } from "react";
import {
  Col,
  Row,
  Container,
  Card,
  Image,
  Button,
  Navbar,
  Nav,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/styles/main.css";
import Meet from "../assets/img/Group 2113.png";
import Perfect from "../assets/img/Group 2104.png";
import BrandLabsLogo from "../assets/img/brandlabs-logo.png";
import Logo from "../assets/img/logo-new 2.png";
import Pc from "../assets/img/Group 2115.png";
import Dabar from "../assets/img/Group 2116.png";
import Family from "../assets/img/BTP Landing Page 3.png";
import Vector from "../assets/img/Vector.png";
import Channa from "../assets/img/Channa Keshava.jpg";
import Charith from "../assets/img/charith kashyap.jpg";
import ParentsGroup from "../assets/img/pGroup.png";
import mobilePGroup from "../assets/img/mobilePGroup.png";
import Campaign from "../assets/svg/Campaign.svg";
import Product from "../assets/svg/Productivity.svg";
import Shopkeep from "../assets/svg/Shopkeeper.svg";
import Tar from "../assets/svg/Target.svg";
import Graph from "../assets/svg/Graph Setting.svg";
import Manage from "../assets/svg/management.svg";
import ManWithPc from "../assets/svg/image 7.svg";
import BrandIm from "../assets/svg/image 13.svg";
import Category from "../assets/svg/image 9.svg";
import Mom from "../assets/svg/image 10.svg";
import SetupManage from "../assets/svg/image 11.svg";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ProfileContext } from "../context/ProfileContext";
import Onboarding from "../services/Onboarding";
import "react-phone-input-2/lib/style.css";
import Spinner from "../services/Spinner";

import { message } from "antd";
import Profile from "../services/Profile";

const Homepage = () => {
  const navigate = useHistory();
  const { login, profile, getProfile } = useContext(ProfileContext);
  const [loading, setLoading] = useState(true);

  const [iframeVisible, setIframeVisible] = useState(false);
  const [loginType, setLogingType] = useState("");

  const handleOpen = async (type) => {
    setLogingType(type);
    setIframeVisible(true);

    const messageListener = async (event) => {
      if (event?.data?.token) {
        console.log("event", event)
        window.removeEventListener("message", messageListener);

        setIframeVisible(false);
        login(event?.data?.token);
        Cookies.set("accessToken", event.data?.token);

        try {
          const res = await Onboarding.getBusinessDetails();
          if (res?.status === 200) {
            const onboardData = res.data?.data?.onboard_data;
            if (onboardData && onboardData?.length > 0) {
              navigate.push("/campaign");
            } else {
              const data = await Profile.getUserDetails();
              if (data?.status === 200) {
                if (data?.data?.c_code?.toLowerCase() === "in") {
                  navigate.push("/onboard");
                } else {
                  navigate.push("/onboarding");
                }
              } else {
                message.error("Something went wrong, please try again");
              }
            }
          }
        } catch (error) {
          message.error("Failed to load profile. Please try again");
        }
      }
    };

    window.addEventListener("message", messageListener);
  };

  return (
    <div>
      <Navbar collapseOnSelect expand="lg" variant="light" className="header">
        <Container>
          <Navbar.Brand href="">
            {" "}
            <Link to="/">
              <img
                src={BrandLabsLogo}
                to="/"
                className="headerLogo"
                alt=""
                // onClick={handleGoBack}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Brand>
            <div>
              <button
                href=""
                className="btnLogin mobile my-2 my-sm-0"
                onClick={() => handleOpen("sign-in")}
              >
                Login
              </button>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link>
                <Link>
                  {/* <Dropdown style={{ backgroundColor: 'transparent' }}>
                    <Dropdown.Toggle
                      variant='transparent'
                      id='dropdown-basic'
                      className='linkPages lp'
                    >
                      Pages
                    </Dropdown.Toggle> */}
                  {/*
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={brandPage}>
                        Brand Pages
                      </Dropdown.Item>
                      <Dropdown.Item onClick={productPage}>
                        Product Pages
                      </Dropdown.Item>
                    </Dropdown.Menu> */}
                  {/* </Dropdown> */}
                </Link>
              </Nav.Link>

              {/* <Nav.Link>
                <a
                  className="linkPages"
                  href="#solution"
                  // onClick={clickSolutions}
                >
                  Solutions
                </a>
              </Nav.Link> */}

              {/* <Nav.Link>
                <Link
                  className='linkPages'
                  to='/utilities'
                  onClick={clickUtilies}
                >
                  Utilities
                </Link>
              </Nav.Link> */}

              {/* desktop login */}

              <Button
                variant="danger"
                className="btnLogin pc"
                // onClick={onClickLogin}
                onClick={() => handleOpen("sign-in")}
              >
                Login
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container">
        <div className="row" id="top-gap">
          <div className="col-sm-6 mobile">
            <img
              src={Meet}
              alt=""
              style={{ width: "100%", height: "100%" }}
              className="loginImageMobile"
            />
          </div>
          <div className="col-sm-6 " id="parentsLeft">
            <div className="parentsDoPlay">
              <h1 className="parentsDoBold">
                Parents do play favorites with Brands
              </h1>
              <p>
                A new mom is born every 8 seconds. She needs your brand now more
                then ever
              </p>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-lg custom-btn reg"
                  onClick={() => handleOpen("")}
                >
                  Register
                </button>
                <span> </span>
                <button
                  type="button"
                  className="btn btn-danger btn-lg custom-btn lgn reg1"
                  onClick={() => handleOpen("sign-in")}
                >
                  Login
                </button>
              </div>
            </div>
          </div>

          <div className="col-sm-6 pc">
            <img
              src={Meet}
              className="loginImage"
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </div>
        </div>
      </div>
      <Container className="text-center yourPerfect mt-4">
        <Image src={Logo} className="btpLogo " />
        <h2 id="solution" className="yourPer">
          Your perfect <span className="solution">solutions!</span>
        </h2>
        <p className="btpPara">
          Our innovative blend of marketing and advertising solutions connect
          brands with parents in powerful ways, at key decision-making moments
          throughout the journey called life
        </p>
        <Image src={Perfect} className="perfectPic" />
      </Container>

      <Container className="text-center containerDoMore">
        <p className="doMore"> Do more with Brand Labs</p>
        <h2 className=" advertising">
          Ready to start <span className="brandLabsRight">advertising ?</span>
        </h2>
      </Container>

      <div className="container brandPageConatiner">
        <div className="row">
          <div className="col-sm-6" id="brandLeft">
            <h2>Brand Page Showcase</h2>
            <p className="brandLeftPara">
              Increase awareness of your business with a free brand page that
              you can make in minutes.
            </p>
            <p className="brandColor">
              {/* See Brand Pages */}
              Coming Soon
            </p>
          </div>
          <div className="col-sm-6">
            <img src={Pc} className="brandImage" alt="" />
          </div>
        </div>
      </div>
      <Container style={{ marginTop: "60px" }}>
        <Row>
          <Col className="sm-6 pc">
            <Row></Row>
            <div className="half-circle"></div>
            <Image src={Dabar} className="dabarPic " />
          </Col>

          <Col className="sm-6" id="preciseRight">
            <h2>Precise Ad Targeting</h2>
            <p>Inovation blend of life-stage marketing</p>
            <p style={{ marginTop: "-20px" }}>
              solutions to Reach and engage new , expectant
            </p>
            <p style={{ marginTop: "-20px" }}>and experienced moms</p>

            <ul className="preciseUl">
              <li>Set clear objectives</li>
              <li>Define your audience</li>
              <li>Make impact</li>
            </ul>
            <Col className="sm-6 mobile">
              <Row className="row">
                <div className="half-circle"></div>
              </Row>
              <Image src={Dabar} className="dabarPic " />
            </Col>
          </Col>
        </Row>
      </Container>
      <Container className="containerBuild">
        <Row>
          <Col className="sm-6">
            <div className="circleBuild">
              <div className="text-left circleBuild1">
                <h2 className="buildRelationship">
                  Build Relationship that lasts
                </h2>
                <p>
                  Build an active and engaged audience for your business. Nuild
                  a lasting relationship with Parents for long term impacts
                </p>
              </div>
            </div>
          </Col>
          <Col className="sm-6 buildCircle">
            <Row>
              <div className="circleBuildMobile"></div>
            </Row>
            <Image src={Family} className="familyImage" />
          </Col>
        </Row>
      </Container>
      <div className="take-step-homepage-barndlabs">
        <div
          className=" text-center goals"
          style={{ backgroundColor: "#FEF8DC" }}
          id="towardLeft"
        >
          <Container>
            <div className="row">
              <div className="col-sm-6" id="buildLeft">
                <h2 className="leftGoals">Take steps towards your goals </h2>
                <p className="leftGoals1">Our secret sauce for your success </p>
              </div>
            </div>
            <div className="onboarding">
              <div className="circle">
                <img src={ManWithPc} alt="" />
                <p id="on-p">Come onboard</p>
              </div>

              <div className="right-line"></div>
              <div className="line"></div>
              <div className="circle">
                <img src={BrandIm} alt="" />
                <p id="on-p">List your brand page</p>
              </div>

              <div className="right-line"></div>
              <div className="line"></div>
              <div className="circle">
                <img src={Category} alt="" />
                <p id="on-p">Categorize your products</p>
              </div>
              <div className="right-line"></div>
              <div className="line"></div>
              <div className="circle">
                <img src={SetupManage} alt="" />
                <p id="on-p">Set up & manage campaigns</p>
              </div>
              <div className="right-line"></div>
              <div className="line"></div>
              <div className="circle">
                <img src={Mom} alt="" />
                <p id="on-p">Get discovered by Moms</p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <section className="simple-tools-for-every">
        <div style={{ backgroundColor: "#F2FEFF" }}>
          <div className="text-center row">
            <div
              className="col-sm-6"
              style={{ marginTop: "40px", marginLeft: "23%" }}
            >
              <h2 className="leftRes">Simple tools for every business </h2>
              <p className="leftRes1">A complete business toolkit</p>
            </div>
          </div>
          <br />
          <div className="container">
            <div className="row">
              <div
                className=" col-sm-4 text-left"
                style={{ marginRight: "0px" }}
              >
                <img src={Product} className="img-responsive" alt="" />
                <h3>Manage Brand Pages</h3>
                <p>
                  Stylizes, emotional representations of your brand using images
                  & videos
                </p>
              </div>

              <div className="col-sm-4 text-left">
                <img src={Shopkeep} className="img-responsive" alt="" />
                <h3>Showcase Your Product</h3>
                <p>Create, categorize and showcase your product cataglogue</p>
              </div>
              <div className="col-sm-4 text-left ">
                <img src={Tar} className="img-responsive" alt="" />
                <h3>Target User Precisely</h3>
                <p>
                  Define your audience for smarter ad targeting. Adjust your
                  target audience to be as broad or well-define as you like
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-4 text-left">
                <img src={Campaign} className="img-responsive" alt="" />
                <h3>Create & Manage Campaigns</h3>
                <p>
                  Select campaign parameters, such as an advertising objective
                  and manage everything on go
                </p>
              </div>
              <div className="col-sm-4 text-left">
                <img src={Graph} className="img-responsive" alt="" />
                <h3>Get Real-time Insights</h3>
                <p>
                  Real time insights and analystics to better understand the
                  impact for your campaign
                </p>
              </div>
              <div className="col-sm-4 text-left">
                <img src={Manage} className="img-responsive" alt="" />
                <h3>Optimize and Get Better</h3>
                <p>
                  Do testing and optimization for Search Engines to improve
                  performance over a period of time
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <Container>
        <div className="text-center">
          <Image src={Vector} className="vectorLogo" />
        </div>

        <div className="row mb-2">
          <div className="col-sm-6">
            <Card className="border-0 card1 h-100">
              <Card.Body>
                <Image src={Channa} className="imgCeo" />
                <div style={{ marginLeft: "150px", marginTop: "-90px" }}>
                  <Card.Title>Channa Keshava </Card.Title>
                  <p>CEO, Kidzavalle</p>
                </div>
                <p style={{ marginTop: "45px" }}>
                  The BTP Brand labs team came onboard early, helping Kidzvalle
                  by providing key insights into user interests and segments,
                  aligned with their target groups.
                </p>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 ">
            <Card className="border-0 card2">
              <Card.Body>
                <Image src={Charith} className="imgCeo" />
                <div style={{ marginLeft: "150px", marginTop: "-90px" }}>
                  <Card.Title>Charith Kashyap </Card.Title>
                  <p>CEO, Selvitate</p>
                </div>
                <p style={{ marginTop: "45px" }}>
                  BTP Brand Labs is a full-funnel solution that reaches
                  high-intent audiences. It’s the only platform where people
                  actively look for what’s next, so you can reach them at
                  critical moments throughout the decision-making process.
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
        <Container className="text-center dotSlider">
          <span className="dot"></span>
        </Container>
      </Container>
      <div className="container mt-4">
        <div className="row">
          <Card className="border-0 wantsToTalk">
            <Card.Body className="" onClick={() => handleLogin("sign-up")}>
              <div>
                <h3 className="parentsHeading">Wants to talk to parents ? </h3>
                <Button className="btn btn-light btnAdvertise">
                  Advertise with us
                </Button>
                <div className="pc">
                  <img
                    className="card-img-top parentWantPic"
                    src={ParentsGroup}
                    alt="Card cap"
                  />
                </div>
                <div className="mobile">
                  <img
                    className="card-img-top parentWantPic"
                    src={mobilePGroup}
                    alt="Card cap"
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <footer className="landing-page-footer">
        <Container className="landing-page-footer">
          <Row className="footer px-0 landing-page-footer">
            <Col>
              <Row className="sub-footer">
                <Col>
                  <div className="footer-menu">
                    <ul className="list-unstyled d-block">
                      <li
                        className="nav-item text-secondary cursor-pointer mb-2"
                        onClick={() => navigate.push("/self-serve-ad-terms")}
                      >
                        Self-serve Ad Terms
                      </li>
                      <li
                        className="nav-item text-secondary cursor-pointer mb-4"
                        onClick={() => navigate.push("/advertising-policy")}
                      >
                        Advertising Standards, Policies and Guidelines
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col className="">
                  <div className="copyright">
                    Copyright © 2014-2024 Mykids Ventures Private Limited.
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="text-end">
              <div className="brand">
                <img src={BrandLabsLogo} alt="" width={250} />
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <div>
        <Modal
          centered
          backdrop="static"
          show={iframeVisible}
          onHide={() => setIframeVisible(false)}
        >
          <Modal.Body>
            {loading && (
              <div className="text-center">
                <Spinner />
                <p>Loading...</p>
              </div>
            )}
            <span style={{ display: loading ? "none" : "block" }}>
              <span className="d-flex align-items-center justify-content-between mb-3">
                <img src={Logo} alt="logo" className=" py-2" width={120} /> 
                <Button className="close-text-btn" onClick={() => setIframeVisible(false)}>X</Button>
              </span>
              <iframe
                src={`${process.env.REACT_APP_SSO_URL}${loginType}?apiKey=${process.env.REACT_APP_API_KEY}&registered_from=brandlabs`}
                id="myIframe"
                title="login-sso"
                width="100%"
                height={530}
                onLoad={() => setLoading(false)}                
              ></iframe>
            </span>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Homepage;
