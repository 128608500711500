import React, { useEffect } from "react";
import { Typography, Divider, Button } from "antd";
import Logo from "../../assets/img/brandlabs-logo.png";
import { useHistory } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

const SelfServeAdTerms = () => {
  const navigate = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    navigate.goBack();
  };

  return (
    <div className="policy-page">
      <div
        className="brand text-center d-flex align-items-center justify-content-between"
        
      >
        <Button
          className="d-flex align-items-center"
          onClick={handleGoBack}
        >
          <LeftCircleOutlined className="fs-6 m-0" />
        </Button>
        <img src={Logo} onClick={handleGoBack} alt="" width={250} />
        <div></div>
      </div>
      <Title level={2} className="text-center mb-4">
        Being The Parent — Self-serve Ad Terms
      </Title>

      <Paragraph>
        The following terms ("<strong>Self-serve Advertising Terms</strong>" or
        "<strong>Self-serve Ad Terms</strong>") apply to your use of Being The
        Parent Products (such as the self-service advertising interfaces and
        APIs) for creation, submission and/or delivery of any advertising or
        other commercial or sponsored activity or content (collectively, "
        <strong>Self-serve Ad Interfaces</strong>") and any order that you place
        through the Self-serve Ad Interfaces (“<strong>Order</strong>").
      </Paragraph>

      <Paragraph>
        You can target your desired audience by buying ads to be delivered on
        Being The Parent, our publisher network or any place where we serve ads.
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            When you place an Order, you will tell us the type of advertising
            you want to buy, the amount you want to spend, and your bid. If we
            accept your Order, we will deliver your ads as inventory becomes
            available. When serving your ad, we use best efforts to deliver the
            ads to the audience you specify or to achieve the outcome that you
            select, although we cannot guarantee in every instance that your ad
            will reach its intended target or achieve the outcome that you
            select.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            Your ads must comply with all applicable laws, regulations, and
            guidelines, as well as our{" "}
            <a href="/advertising-policy" className="text-danger fw-bold">
              Advertising Standards, Policies and Guidelines
            </a>
            . Failure to comply may result in a variety of consequences,
            including the cancellation of ads that you have placed and
            termination of your account.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>We may reject or remove any ad for any reason.</li>
        </ul>
      </Paragraph>

      <Paragraph>
        <Paragraph>
          <ul>
            <li>
              You will pay for your Orders in accordance with the following:
              <ul>
                <li>
                  You will pay all amounts specified in each Order you place,
                  along with any applicable taxes. The amount you owe for each
                  Order will be calculated based on our tracking mechanisms.
                </li>
                <li>
                  You are responsible for maintaining the security of your
                  advertising account, and you understand that you will be
                  charged for any Orders placed on or through your advertising
                  account.
                </li>
                <li>
                  You can cancel an Order at any time, but your ads may run for
                  24 hours after you notify us, and you are still responsible
                  for paying for all ads that run.
                </li>
                <li>
                  The amounts that we charge you may be subject to and include
                  applicable taxes and levies, including without limitation
                  withholding taxes. You are responsible for bearing and
                  remitting any taxes that apply to your transactions. You will
                  indemnify and hold us harmless from and against any claim
                  arising out of your failure to do so.
                </li>
                <li>
                  You will purchase ad inventory with an "Advertiser Balance",
                  which is a pre-paid balance that can be used solely to
                  purchase ads on Being The Parent. Advertiser Balances are only
                  for business or commercial purposes. Being The Parent is not a
                  bank and does not offer banking services; accordingly,
                  Advertiser Balances do not earn interest, are not deposit
                  obligations, and are not insured.
                </li>
                <li>
                  “Advertiser Balance” will be available by loading money into
                  Being The Parent Advertising wallet. This “Advertiser Balance”
                  will be inclusive of all applicable taxes.
                </li>
              </ul>
            </li>
          </ul>
        </Paragraph>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            You understand that, from time to time, we run tests on our
            Self-serve Ad Interfaces and related systems, which may affect your
            use and experience thereof, including campaign performance. You
            acknowledge and agree that we may test as notified in these Terms,
            including to assess formatting, relevance, pricing, reporting,
            targeting, and delivery.
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            We will determine the size, placement, and positioning of your ads.
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            Scheduling of delivery is subject to availability and may not be
            continuous.
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            We do not guarantee the reach or performance that your ads will
            receive, such as the number of people who will see your ads or the
            number of clicks your ads will get.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            Our license to deliver your ad will end when we have completed your
            Order:
            <ul>
              <li>
                You understand, however, that once displayed, ads are public
                information. Ads may be re-shared and accessed outside of the
                targeted audience (including from Being The Parent app &
                website, other Being The Parent products, or Being The Parent
                social media handles running the ads). If users have interacted
                with your ad, your ad may remain on our Products (e.g. shared
                until the users delete it).
              </li>
              <li>
                You consent that Being The Parent may disclose your advertising
                content, and all information associated with your advertising,
                to a governmental entity or body if Being The Parent believes
                that disclosure would assist in a lawful investigation.
              </li>
            </ul>
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            We will provide you with reports about the kinds of people seeing
            your ads and how your ads are performing. Your use of these reports
            is subject to the Data Use Restrictions in our{" "}
            <a href="/advertising-policy" className="text-danger fw-bold">
              Advertising Standards, Policies and Guidelines
            </a>
            .
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <ul>
          <li>
            You will not issue any press release or make public statements about
            your relationship with Being The Parent or Being The Parent Products
            without our prior written permission.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            If you are placing ads on someone else's behalf, you must have
            permission to place those ads, and agree as follows:
            <ul>
              <li>
                You represent and warrant that you have the authority to and
                will bind the advertiser to these Self-serve Ad Terms, to which
                you also agree.
              </li>
              <li>
                If the advertiser you represent violates these Self-serve Ad
                Terms, we may hold you responsible for that violation.
              </li>
              <li>
                You agree that we may provide campaign reporting information to
                the end advertiser for whom you placed a campaign.
              </li>
            </ul>
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            We may ask you to review and accept supplemental terms that apply to
            your use of a specific feature or functionality made available
            through the Self-serve Ad Interfaces. To the extent that those
            supplemental terms conflict with these Self-serve Ad Terms, the
            supplemental terms will govern with respect to your use of the
            specific feature or functionality to the extent of the conflict. We
            may change or update these Self-serve Ad Terms from time to time and
            your continued use of the Self-serve Ad Interfaces constitutes
            acceptance of those changes.
          </li>
        </ul>
      </Paragraph>

      <Paragraph>
        <ul>
          <li>
            Third-party claims: If anyone brings a claim, cause of action or
            dispute against us related to your services, actions, content or
            information on Being The Parent Products or your use of any Being
            The Parent Products, you agree to indemnify and hold us harmless
            from and against any damages, losses and expenses of any kind
            (including reasonable legal fees and costs) related to any such
            claim, cause of action or dispute.
          </li>
        </ul>
      </Paragraph>

      <Paragraph strong>Effective date: 19 February 2024</Paragraph>

      <Paragraph className="text-center mt-5">
        <Text type="secondary">
          Copyright © 2014-2024 Mykids Ventures Private Limited.
        </Text>
      </Paragraph>
    </div>
  );
};

export default SelfServeAdTerms;
