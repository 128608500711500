import { Upload, Button } from "antd";

import { ToTopOutlined } from "@ant-design/icons";
import File from "../../services/fileUpload";


export default function FileUpload(props) {

  const customRequest =  async(file, imageType ) => {
    try {
        const fileData = await File.uploadFile(
        props?.folder,
        file?.file
      );
      if (fileData?.data?.imageUrl) {
        const imageData = {
          url: fileData?.data?.imageUrl,
          key: fileData?.data?.key,
          imageUrl: fileData?.data?.imageUrl,
          size: file?.file?.size,
          name: file?.file?.name,
          type: file?.file?.type,
        }
        if(imageType === 'singleImage'){
          props?.setSingleImage([imageData]);
        }
        if(imageType === 'multipleImages'){
          const multipleImages = props?.multipleImages;
          multipleImages?.push(imageData);
          props?.setMultipleImages((prevImages) => {
            const uniqueImages = new Set([...prevImages, imageData]);
            return Array.from(uniqueImages);
          });
        }
      }
    } catch (error) {
      file.onError('Upload failed'); 
    };
  }

  const handleImgRemove = (file) => {
    if (props?.multiple === true) {
      const updatedFileList = props?.multipleImages.filter((item) => item?.uid !== file?.uid);
      props?.setMultipleImages(updatedFileList);
    } else {
      props?.setSingleImage([]);
    }
  };
  
  return (
    <Upload
      customRequest={(file) => customRequest(file, props?.multiple === true ? 'multipleImages' : 'singleImage')}
      fileList={props?.multiple === true ? props?.multipleImages : props?.singleImage}
      onRemove={(file) => handleImgRemove(file)}
      listType="picture-card"
      multiple={props?.multiple}
      >
      <Button
        type="dashed"
        className="ant-full-box"
        icon={<ToTopOutlined />}
      >
        Click to Upload
      </Button>
    </Upload>
  )
}