import { BTPAuth } from "./BTPAuth";
import apiUrl from "../config/btpApi";
import errorHandling from "../components/errorhandling/ErrorHandle";

const Campaigns = {
  getCampaigns: async () => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignUrl + "get-all?limit=100",
        "get"
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  getCampaignById: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignUrl + "get-all/?campaign_id=" + id,
        "get",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  deleteCampaign: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignUrl + id,
        "delete",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  editCampaign: async (id, data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignUrl + id,
        "patch",
        {},
        data
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  createCampaign: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignUrl + "create",
        "post",
        {},
        data
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  campaignReview: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignUrl + `submit-review/${id}`,
        "patch",
        "",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  manageCampaign: async (id, action) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignUrl + `manage/${id}?action=${action}`,
        "patch",
        "",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  campaignReports: async (id, date) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.campaignReportsUrl +
          `?startDate=${date?.startDate}&endDate=${date?.endDate}&component=ad&id=${id}`,
        "post",
        "",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },
};

export default Campaigns;
