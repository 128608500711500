import React, { useEffect } from "react";
import { Button, Typography } from "antd";
import Logo from "../../assets/img/brandlabs-logo.png";
import { useHistory } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph, Text } = Typography;

const AdvertisingPolicy = () => {
  const navigate = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("window.history", window.history);
  const handleGoBack = () => {
    console.log("window.history", window.history);
    navigate.goBack();
  };

  return (
    <div className="policy-page">
      <div className="brand text-center d-flex align-items-center justify-content-between">
        <Button
          className="d-flex align-items-center"
          onClick={() => handleGoBack()}
        >
          <LeftCircleOutlined className="fs-6 m-0" />
        </Button>
        <img src={Logo} onClick={handleGoBack} alt="" width={250} />
        <div></div>
      </div>
      <Title level={2}>
        Being The Parent Advertising Standards, Policies and Guidelines
      </Title>

      <Paragraph>
        <Text strong>1. Overview</Text>
      </Paragraph>

      <Paragraph>
        <Text strong>Understanding our Advertising Standards:</Text>
      </Paragraph>

      <Paragraph>
        Our Advertising Standards provide policy detail and guidance on the
        types of ad content we allow, and the types of ad content we prohibit.
        When advertisers place an order, each ad is reviewed against our
        policies. Our Advertising Standards also provide guidance on advertiser
        behaviour that may result in advertising restrictions being placed on a
        business account or its assets (an ad account, Group or user account).
        <br />
        If you think that your ad was mistakenly rejected, or if you think that
        your business account was mistakenly restricted, you can request a
        review of either decision by writing to{" "}
        <a href="mailto:support@beingtheparent.com" className="text-danger">
          support@beingtheparent.com
        </a>
        .
      </Paragraph>

      <Paragraph>
        <Text strong>2. Being The Parent advertising policy principles</Text>
      </Paragraph>

      <Paragraph>
        Advertisers contribute to the Being The Parent platform and community in
        many ways, including highlighting new products and services or drawing
        attention to events and issues. To help keep both businesses and
        organisations who use our ad tools safe, and create a welcoming
        environment for everyone who uses our products and services, we have put
        in place our Advertising Standards to guide what is allowed across Being
        The Parent.
        <br />
        Advertisers running ads across Being The Parent must follow our
        Advertising Standards.
      </Paragraph>

      <Paragraph>
        Our policies require all advertisers to comply with the laws in their
        jurisdiction, not engage in discriminatory practices, and not sell
        illegal or unsafe substances.
      </Paragraph>

      <Paragraph>
        Our policies prohibit ads promoting products, services, schemes or
        offers using deceptive or misleading practices, including those meant to
        scam people out of money or personal information.
      </Paragraph>

      <Paragraph>
        Our policies prohibit ads containing shocking, sensational or
        excessively violent content, certain adult content and profanity.
      </Paragraph>

      <Paragraph>
        Ads must not discriminate or encourage discrimination against people
        based on personal attributes such as race, ethnicity, colour, national
        origin, religion, age, sex, sexual orientation, gender identity, family
        status, disability, medical or genetic condition.
      </Paragraph>

      <Paragraph>
        Ads must not contain claims that a person or group of people are a
        threat to the physical safety, health or survival of others on the basis
        of race, ethnicity, national origin, religious affiliation, sexual
        orientation, caste, sex, gender, gender identity, serious disease,
        disability or immigration status.
      </Paragraph>

      <Paragraph>
        <Text strong>3. The ad review process</Text>
      </Paragraph>

      <Paragraph>
        Our ad review system checks ads and business assets against our
        policies. Our ad review process starts automatically before ads begin
        running, and is typically completed within 24 - 48hours, although it may
        take longer in some cases. During this review, the status of the ad will
        be "In review". In addition, ads may be reviewed again, including after
        they've gone live.
      </Paragraph>

      <Paragraph>
        <Text strong>What is reviewed</Text>
      </Paragraph>

      <Paragraph>
        The ad review system reviews ads for violations of our policies. This
        review process may include the specific components of an ad, such as
        images, video and text, as well as an ad's associated landing page or
        other destinations, among other information.
      </Paragraph>

      <Paragraph>
        <Text strong>Outcome of review</Text>
      </Paragraph>

      <Paragraph>
        If a violation is found at any point in the review process, the ad will
        be rejected, and the business account or its assets may be restricted.
        Lower-quality ads that do not necessarily violate our policies may
        experience an impact on performance.
      </Paragraph>

      <Paragraph>
        <Text strong>Re-review of ads</Text>
      </Paragraph>

      <Paragraph>
        Ads remain subject to review and re-review at all times, and may be
        rejected or restricted for violation of our policies at any time. It is
        your responsibility to understand and comply with our policies.
      </Paragraph>

      <Paragraph>
        <Text strong>4. What to do if your ad is rejected</Text>
      </Paragraph>

      <Paragraph>
        Create a new ad or edit your ad. You may create a new ad or edit your ad
        to comply with our policies. These ads will be treated as new ads and
        reviewed by our ad review system.
      </Paragraph>

      <Paragraph>
        <Text strong>5. Data use restrictions</Text>
      </Paragraph>

      <Paragraph>
        Ensure that any ad data collected, received or derived from your Being
        The Parent ad (“Being The Parent advertising data") is only shared with
        someone acting on your behalf, such as your service provider. You are
        responsible for ensuring that your service providers protect any Being
        The Parent advertising data or any obtained from us, limit their use of
        all of that information and keep it confidential and secure.
      </Paragraph>

      <Paragraph className="text-center mt-5">
        <Text type="secondary">
          Copyright © 2014-2024 Mykids Ventures Private Limited.
        </Text>
      </Paragraph>
    </div>
  );
};

export default AdvertisingPolicy;
