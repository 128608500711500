import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import SendEmail from "../../services/SendEmail.js";
import FileUpload from "../fileupload/FileUpload";

const ContactModal = ({ visible, onClose, onSend }) => {
  const [form] = Form.useForm();

  const [text, setText] = useState("");

  const handleSend = () => {
    form.validateFields().then(async (values) => {
      const res = await SendEmail.contactUs(values);
      if (res?.status == 200) {
        onClose();
        form.resetFields();
        message.success("Your Query is Sent Successfully!");
      }
    });
  };

  const [multipleImages, setMultipleImages] = useState([]);

  useEffect(() => console.log("text", multipleImages), [multipleImages]);

  return (
    <Modal
      title="Contact Us"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="send" type="primary" onClick={handleSend}>
          Send
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: "Please enter the subject",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="message"
          label="Message"
          rules={[
            {
              required: true,
              message: "Please enter your message",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        {/* <Form.Item
          name="featuredImg"
          label="Upload Media"
          // rules={[{ required: true }, { type: "string", validator: checkFeaturedImage }]}
        >
          <FileUpload
            folder="sponsored-post"
            multiple={true}
            multipleImages={multipleImages}
            setMultipleImages={setMultipleImages}
          />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default ContactModal;
