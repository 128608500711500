import { Layout, Row, Col } from "antd";
import logo from "../../assets/img/brandlabs-logo.png";
import { useHistory } from "react-router-dom";


function Footer() {
  const { Footer: AntFooter } = Layout;

  const navigate = useHistory()

  return (
    <AntFooter>
      <Row className="footer">
        <Col>
          <Row className="sub-footer">
            <Col>
              <div className="footer-menu">
                <ul>
                  <li className="nav-item pe-3 text-secondary cursor-pointer" onClick={() => navigate.push("/self-serve-ad-terms")}>
                    {/* <a href="/self-serve-ad-terms" className="nav-link ps-0"> */}
                      Self-serve Ad Terms
                    {/* </a> */}
                  </li>
                  <li className="nav-item text-secondary cursor-pointer" onClick={() => navigate.push("/advertising-policy")}>
                    {/* <a href="/advertising-policy" className="nav-link ps-0"> */}
                      Advertising Standards, Policies and Guidelines
                    {/* </a> */}
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="">
              <div className="copyright">
                Copyright © 2014-2024 Mykids Ventures Private Limited.
              </div>
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="brand">
            <img src={logo} alt="" width={250} />
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
