import { Button, Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/img/brandlabs-logo.png";
import collapsedLogo from "../../assets/img/favicon.png";
import React, { useState } from "react";
import ContactModal from "../Modals/ContactUs";

function Sidenav({ color, collapsed }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const [isContactModalVisible, setContactModalVisible] = useState(false);

  const handleContactClick = () => {
    setContactModalVisible(true);
  };

  const handleContactClose = () => {
    setContactModalVisible(false);
  };

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const premium = [
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M21.2501 3C21.4925 3 21.7176 3.11688 21.8574 3.30983L21.9119 3.39706L25.9186 10.9098L25.9615 11.0122L25.9731 11.05L25.9901 11.1273L25.9994 11.2153L25.9973 11.3147L26.0001 11.25C26.0001 11.3551 25.9785 11.4552 25.9394 11.5461L25.9106 11.6057L25.87 11.6723L25.8173 11.7408L14.6 24.7047C14.4999 24.8391 14.3628 24.9277 14.2139 24.9703L14.1559 24.9844L14.0585 24.9979L13.9999 25L13.8993 24.9932L13.8142 24.9771L13.7109 24.9432L13.6852 24.931C13.5949 24.8911 13.5119 24.8316 13.4425 24.7535L2.17081 11.7263L2.1087 11.6387L2.06079 11.5456L2.02611 11.4463L2.00297 11.3152L2.00269 11.1878L2.01755 11.0891L2.02714 11.0499L2.06104 10.9538L2.08838 10.8971L6.08838 3.39706C6.20243 3.18321 6.41149 3.0396 6.64753 3.00704L6.75014 3H21.2501ZM17.9061 12H10.0911L14.0011 22.16L17.9061 12ZM8.48514 12H4.38914L11.7621 20.518L8.48514 12ZM23.6081 12H19.5151L16.2421 20.511L23.6081 12ZM10.0241 4.499H7.19914L3.99814 10.5H8.42314L10.0241 4.499ZM16.4231 4.499H11.5761L9.97514 10.5H18.0231L16.4231 4.499ZM20.8001 4.499H17.9751L19.5761 10.5H23.9991L20.8001 4.499Z"
          fill="#212121"
        ></path>{" "}
      </g>
    </svg>,
  ];

  const campaigns = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const sponsoredPosts = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  // const rtl = [
  //   <svg
  //     width="20"
  //     height="20"
  //     viewBox="0 0 20 20"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //     key={0}
  //   >
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
  //       fill={color}
  //     ></path>
  //   </svg>,
  // ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];


  return collapsed ? (

    <React.Fragment>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />

      <Menu theme="light" mode="inline" inlineCollapsed={true}>

        <Menu.Item className="menu-item-header" key="5">
          Pages
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/dashboard" className="text-decoration-none">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label ">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/sponsored-posts" className="text-decoration-none">
            <span
              className="icon"
              style={{
                background: page === "sponsored-posts" ? color : "",
              }}
            >
              {sponsoredPosts}
            </span>
            <span className="label ">Sponsored Posts</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="1">
          <NavLink to="/campaign" className="text-decoration-none">
            <span
              className="icon"
              style={{
                background: page === "campaign" ? color : "",
              }}
            >
              {campaigns}
            </span>
            <span className="label ">Campaigns</span>
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="4">
          <NavLink to="/premium" className="text-decoration-none">
            <span
              className="icon"
              style={{
                background: page === "premium" ? color : "",
              }}
            >
              {premium}
            </span>
            <span className="label ">Premium</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item className="menu-item-header" key="8">
          Account
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/wallet" className="text-decoration-none">
            <span
              className="icon"
              style={{
                background: page === "wallet" ? color : "",
              }}
            >
              {sponsoredPosts}
            </span>
            <span className="label">Wallet</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile" className="text-decoration-none">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item>
      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {campaigns}
          </span>
          <h6>Need Help?</h6>
          <p></p>
          <Button
            type="primary"
            className="ant-btn-sm ant-btn-block"
            onClick={handleContactClick}
          >
            Contact Us
          </Button>
        </div>
      </div>
      <ContactModal
        visible={isContactModalVisible}
        onClose={handleContactClose}
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="brand">
        <img src={collapsedLogo} alt="" />
      </div>
      <hr />

      <Menu theme="light" mode="inline" inlineCollapsed={true}>
        {/* <Menu.Item className="menu-item-header" key="5">
          Pages
        </Menu.Item> */}
        <Menu.Item key="2">
          <NavLink to="/dashboard" className="text-decoration-none-collapsed">
            <span
              className="icon m-0"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {dashboard}
            </span>
            {/* <span className="label ">Dashboard</span> */}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/sponsored-posts" className="text-decoration-none-collapsed">
            <span
              className="icon m-0"
              style={{
                background: page === "sponsored-posts" ? color : "",
              }}
            >
              {sponsoredPosts}
            </span>
            {/* <span className="label ">Sponsored Posts</span> */}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="1">
          <NavLink to="/campaign" className="text-decoration-none-collapsed">
            <span
              className="icon m-0"
              style={{
                background: page === "campaign" ? color : "",
              }}
            >
              {campaigns}
            </span>
            {/* <span className="label ">Campaigns</span> */}
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="4">
          <NavLink to="/premium" className="text-decoration-none-collapsed">
            <span
              className="icon m-0"
              style={{
                background: page === "premium" ? color : "",
              }}
            >
              {premium}
            </span>            
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item className="menu-item-header" key="8">
          Account
        </Menu.Item> */}
        <Menu.Item key="7">
          <NavLink to="/wallet" className="text-decoration-none-collapsed">
            <span
              className="icon m-0"
              style={{
                background: page === "wallet" ? color : "",
              }}
            >
              {sponsoredPosts}
            </span>
            {/* <span className="label">Wallet</span> */}
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile" className="text-decoration-none-collapsed">
            <span
              className="icon m-0"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            {/* <span className="label">Profile</span> */}
          </NavLink>
        </Menu.Item>
      </Menu>
      {/* <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            {campaigns}
          </span>
          <h6>Need Help?</h6>
          <p></p>
          <Button
            type="primary"
            className="ant-btn-sm ant-btn-block"
            onClick={handleContactClick}
          >
            Contact Us
          </Button>
        </div>
      </div>
      <ContactModal
        visible={isContactModalVisible}
        onClose={handleContactClose}
      /> */}
    </React.Fragment>
  );
}

export default Sidenav;
