import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import errorHandling from "../components/errorhandling/ErrorHandle";

const Onboarding = {
  submitOnboardData: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.onBoardingUrl + "create",
        "post",
        {},
        data
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  getBusinessDetails: async () => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.onBoardingUrl + "get-all",
        "get"
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  getBusinessDetailsFromGST: async (gstNum) => {
    
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.getGstDataUrl + gstNum,
        "get",
        {
          "X-RapidAPI-Key":
            "ff80302e86msh25067e05e36942bp14ef67jsn6532fa922cb1",
        }
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data?.message);
      return err?.response;
    }
  },
};

export default Onboarding;
