import { message } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const errorHandling = {
  handleErrors: async function (response, data) {

    if (response?.status === "401" || response?.status === "403") {
      await message.error(response?.message);
      window.location = "/";
    } 
    // else if (data !== "IN" || "") {
    //   window.location = "/abroad";
    // } else if (data == "IN") {
    //   window.location = "/on-boarding";
    // } 
    else if (
      response === "User is not not onboarded" ||
      response?.errorMsg === "User is not not onboarded"
    ) {
     // window.location = "/on-boarding";
    } else {
      if (response?.message) {
        await message.error(response?.message);
      } else if (response?.msg) {
        
        await message.error(response?.msg);
      } else {
        await message.error(response);
        return;
      }
    }
    return true;
  },
};

export default errorHandling;
