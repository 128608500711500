import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Profile  from '../../services/Profile';
import Cookies from "js-cookie";
const InternalLogin = () => {
  const history = useHistory();
  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const exchangeToken = searchParams.get('exchangeToken');
    
    if(exchangeToken){
      (async () => {
        let data = await Profile.internalUserLogIn(exchangeToken);
        
        Cookies.set("accessToken",  data?.token);
        if(data){
            history.push("/campaign");
        }
        else{
            //history.push('/');
        }
      })();
    }
    else {
      window.location.reload('/login');
    }
  });

  return (
    <div style={{ backgroundColor: "rgba(241, 241, 241, 1)" }}>
      {/* <div className="my-2.5">
        <img className="w-32 m-auto" src={partner_image} />
      </div> */}
  </div>
  );
};

export default InternalLogin;
