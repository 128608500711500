import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import propTypes from "prop-types";
import ProfileContext from "../context/ProfileContext";
import { useLocation } from "react-router-dom";

const OnboardRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const authToken = Cookies.get("accessToken");
  const { businessInfo } = useContext(ProfileContext);

  OnboardRoute.propTypes = {
    component: propTypes.any,
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        authToken && Object.keys(businessInfo)?.length === 0 ? (
          <Component {...props} />
        ) : (
          <Redirect to="/campaign" />
        )
      }
    />
  );
};

export default OnboardRoute;
