import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import errorHandling from "../components/errorhandling/ErrorHandle";


const Profile = {
  getUserDetails: async () => {
    try {
      const response = await BTPAuth.fetchData(apiUrl.profileUrl, "get");
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  getOtherUserDetails: async (userId) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.otherUsersProfile,
        "post",
        {},
        {"id": userId}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  getUsers: async (page, queryData) => {
    let data = {};
    let query = { ...queryData, user_status: 1 };
    data = {
      table: "mst_user",
      clause: "AND",
      query: query,
      sortBy: {
        column: "created_date_time",
        order: "DESC",
      },
    };

    let queryParams = {
      page: page.page,
      limit: page.limit,
    };
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.searchUrl +
          "?page=" +
          (queryParams.page + 1) +
          "&limit=" +
          queryParams.limit +
          "&total=" +
          true,
        "post",
        {},
        data
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  getInternalUser: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.createInternalUserUrl + "?userId=" + id,
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  getInpersonatelUser: async (id) => {    
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.impersonateUserUrl,
        "post",
        {},
        id
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  getCreatedBy: async (id) => {    
    try {
      const response = await BTPAuth.fetchData(apiUrl.createdByUserDetail + '?userId=' + id, "get");
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  updateUser: async (data) => {
    //
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.updateUserUrl,
        "patch",
        {},
        data
      );
      return response;
    } catch (err) {
      // errorHandling.handleErrors(err, err.response.status);
      return;
    }
  },

  updatePassword: async (password) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.updatePassword,
        "post",
        {},
        password
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  deleteUser: async (id) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.deleteUserUrl,
        "post",
        {},
        id
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  registerUser: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.registerUrl,
        "post",
        {},
        data
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  deCodeUtm: async (data) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.utmDecodeUrl,
        "post",
        {},
        data
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  },

  
  internalUserLogIn: async (exchangeToken) => {
    try {
      const response = await BTPAuth.fetchData(
        apiUrl.internalLogin + "?exchangeToken=" + exchangeToken,
        "get"
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return;
    }
  }
}


export default Profile;
