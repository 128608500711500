import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Col, DatePicker, Form, Row, Select, Slider } from "antd";
import { ProfileContext } from "../../context/ProfileContext";

const stageData = [
  { label: "Planning for a baby", value: "1,5" },
  { label: "Pregnancy", value: "2,4" },
  { label: "Have a baby", value: "3" },
];

const { RangePicker } = DatePicker;

const gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const trimester = [
  { label: "1st trimester (0-3 months)", value: "1" },
  { label: "2nd trimester (4-6 months)", value: "2" },
  { label: "3rd trimester (7-9 months)", value: "3" },
];

const city = [
  { label: "Bangalore", value: "Bangalore" },
  { label: "Chennai", value: "Chennai" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Mumbai", value: "Mumbai" },
  { label: "Pune", value: "Pune" },
  { label: "Delhi", value: "Delhi" },
  { label: "Kolkata", value: "Kolkata" },
];

const handleStage = (val) =>{
  switch (val) {
    case "1":    
      return "1,5"
    case "2":    
      return  "2,4"
    case "3": 
      return "3"
    default:
      return "";
  }
}

const SelectTarget = ({ form, onFinish, id }) => {
  const { campaignData } = useContext(ProfileContext);

  const [stage, setStage] = useState(
    campaignData?.target_audience_include_set?.stage?.includes("2")
  );
  const [date, setDate] = useState(
  );

  const handleStage = (input) => {
    if(!input?.length) {
        setDate()
    }
    setStage(input)
  }

  return (
    <>
      <br></br>
      <h2 className="mb-4">Audience</h2>
      <Form
        name="validateOnly"
        className="mt-3"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={13}>
            <Form.Item
              name="stage"
              label="Choose target lifestage(s)"
              className="mb-4"
              // rules={[{ required: true, message: "Please select a lifestage" }]}
            >
              <Checkbox.Group
                options={stageData}
                onChange={(checkedValues) => handleStage(checkedValues)}                
              />
            </Form.Item>
          </Col>
          {/* {stage && (
            <Col span={13}>
              <Form.Item
                name="trimester"
                label="Choose target trimester(s)"
                className="mb-4"
                // rules={[
                //   { required: true, message: "Please select a trimester" },
                // ]}
              >
                <Checkbox.Group options={trimester} />
              </Form.Item>
            </Col>
          )} */}
          <Col span={13}>
            <Form.Item
              name="date range"
              label="Start Date   -   End Date"
              className="mb-4"
              rules={[{ required: true }]}
            >
              <RangePicker
                onChange={(val) => setDate(val)}
                // disabledDate={disabledDate}
                disabled={!stage?.length}
                allowEmpty={[false, true]}
              />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              name="location"
              label="Target location(s)"
              className="mb-4"
              
              // rules={[{ required: true, message: "Please select a location" }]}
            >
              <Select disabled={!date} mode="tags" options={city} />
            </Form.Item>
          </Col>
          {/* <Col span={13}>
            <Form.Item
              name="gender"
              label="Target gender(s)"
              className="mb-4"
              // rules={[{ required: true, message: "Please select a gender" }]}
            >
              <Checkbox.Group options={gender}  />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              name="age"
              label="Target age range"
              className="mb-4"
              // rules={[{ required: true, message: "Please enter an age range" }]}
              validateDebounce={1000}
              // initialValue={[20, 26]}
            >
              <Slider
                range
                min={18}
                max={60}
                // tooltipVisible={true}
                tooltipPlacement="bottom"
                style={{ width: "100%" }}
                marks={{ 18: "18", 60: "60" }}
                defaultValue={[20, 26]}
              />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </>
  );
};

export default SelectTarget;
