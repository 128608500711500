import apiUrl from "../config/btpApi";
import { BTPAuth } from "./BTPAuth";
import errorHandling from "../components/errorhandling/ErrorHandle";
const FormData = require("form-data");

const File = {
  uploadFile: async (folderName, fileData) => {
    const form = new FormData();
    form.append("file", fileData);
    form.append("name", fileData?.name);
    form.append("type", fileData?.type);
    form.append("size", fileData?.size);
    form.append("key", "");
    try {
      const res = await BTPAuth.fetchData(
        apiUrl.mediaUrl + "v1/upload?folder=" + folderName,
        "post",
        { "Content-Type": "multipart/form-data;boundary=${form._boundary}`" },
        form
      );
      return res;
    } catch (err) {
      // errorHandling.handleErrors(err?.response?.data);
      return;
    }
  },

  getFileDetails: async (id) => {
    try {
      const res = await BTPAuth.fetchData(apiUrl.fileDetailsApi + id, "get");
      return res.data;
    } catch (err) {
      // errorHandling.handleErrors(err?.response?.data);
      return;
    }
  },
};

export default File;
