import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import OnboardRoute from "./utils/OnboardRoute";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import Homepage from "./pages/LandingPage";
import InternalLogin from "./pages/InternalLogin/InternalLogin";
import { Card, Skeleton } from "antd";

import AdvertisingPolicy from "./components/policy/AdvertisingPolicy"
import SelfServeAdTerms from "./components/policy/SelfServeAdTerms"
import Test from "./pages/Test";

import SelectTarget from "./pages/Premium/SelectTarget";


const Dashboard = lazy(() => import("./pages/Dashboard"));
const CreateCreative = lazy(() => import("./components/forms/CreateCreative"));
const SponsoredPost = lazy(() => import("./pages/SponsoredPost/SponsoredPost"));
const Profile = lazy(() => import("./pages/Profile"));
const CreateCampaign = lazy(() => import("./pages/Campaigns/CreateCampaign"));
const CreateSponsoredPost = lazy(() =>
  import("./pages/SponsoredPost/CreateSponsoredPost")
);
const SponsoredPostDetails = lazy(() =>
  import("./pages/SponsoredPost/SponsoredPostDetails")
);
const CampaignList = lazy(() => import("./pages/Ads/Ads"));
const AdsReport = lazy(() => import("./pages/Ads/AdsReport"));
const Onboard = lazy(() => import("./pages/Onboarding/index"));
const Leads = lazy(() => import("./pages/Ads/LeadGen"));
const ForeignOnboard = lazy(() => import("./pages/Onboarding/ForeignOnboard"));
const Wallet = lazy(() => import("./pages/Wallet"));
const AboutPremium = lazy(() => import("./pages/Premium/AboutPremium"));
const CreatePremium = lazy(() => import("./pages/Premium/CreatePremium"));

function App() {
  const token = Cookies.get("accessToken");
  const location = useLocation();

  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Homepage} />
        {/* <Route path="/test" exact component={Test} /> */}
        <Route path="/advertising-policy" exact component={AdvertisingPolicy} />
        <Route path="/self-serve-ad-terms" exact component={SelfServeAdTerms} />
        <OnboardRoute path="/onboarding" exact component={ForeignOnboard} />
        <OnboardRoute path="/onboard" exact component={Onboard} />
        <Route exact path="/internal-login" component={InternalLogin} />
        <Main>
          <Suspense
            fallback={
              <Card>
                <Skeleton
                  avatar
                  paragraph={{
                    rows: 4,
                  }}
                  active
                />
              </Card>
            }
          >
            <PrivateRoute exact path="/campaign" component={CampaignList} />
            <PrivateRoute
              exact
              path="/campaign/create-campaign"
              component={CreateCampaign}
            />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute
              exact
              path="/dashboard/create-creative"
              component={CreateCreative}
            />
            <PrivateRoute
              exact
              path="/sponsored-posts"
              component={SponsoredPost}
            />
            <PrivateRoute
              exact
              path="/sponsored-posts/sponsored-post-details/:id"
              component={SponsoredPostDetails}
            />
            <PrivateRoute
              exact
              path="/campaign/edit-campaign/:id"
              component={CreateCampaign}
            />
            <PrivateRoute
              exact
              path="/sponsored-posts/edit-post/:id"
              component={CreateSponsoredPost}
            />
            <PrivateRoute
              exact
              path="/sponsored-posts/create-post"
              component={CreateSponsoredPost}
            />
            <PrivateRoute
              exact
              path="/campaign/campaign-report/:id"
              component={AdsReport}
            />
            <PrivateRoute exact path="/campaign/leads/:id" component={Leads} />
            <PrivateRoute exact path="/wallet" component={Wallet} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/premium" component={AboutPremium} />
            <PrivateRoute exact path="/premium/create-premium" component={CreatePremium} />
            <PrivateRoute exact path="/premium/select-target" component={SelectTarget} />
            <Redirect from="*" to={token ? location.pathname : "/"} />
          </Suspense>
        </Main>
      </Switch>
    </div>
  );
}

export default App;
