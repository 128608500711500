import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import propTypes from "prop-types";
import ProfileContext from "../context/ProfileContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authToken = Cookies.get("accessToken");
  const { businessInfo } = useContext(ProfileContext);
  PrivateRoute.propTypes = {
    component: propTypes.any,
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          // Object.keys(businessInfo).length > 0 ? (
            <Component {...props} />
          // ) : (
          //   <Redirect to="/onboard" />
          // )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
